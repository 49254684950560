<template>
<div>
  <player v-if="recording && recording.endTime" class="mt-2 w-100" :url="getRecordingURL($route.params.eventId, $route.params.recordingId)"/>
  <h2 v-if="recording">{{ recording.title }}</h2>
  <h3 v-if="event">
    <font-awesome-icon class="mt-2 mr-2" icon="calendar" :style="{ color: 'grey' }"/>
    {{ event.title }}
  </h3>
  <shareable 
    :config="{hideContent:true}"
    :content="`${$app.appURL}/guest/event/${$route.params.eventId}/watch/${$route.params.recordingId}`"
    shareMessage="Check out this video!"
    />
  <div class="mt-2 font-weight-bold">Comments</div>
  <chat class="mb-5"
    :channel="`${$route.params.eventId}_${$route.params.recordingId}`"
    :author="$parent.user.name"
  />
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("event-watch");
import { db } from '@/services/db';
import { getRecordingURL } from "@/services/recordings";

export default {
  components: {
    player: () => import('@/views/tests/TestHLS.vue'),
    chat: () => import('@/components/chat.vue'),
    shareable: () => import('@/components/shareable.vue'),
  },
  data() {
    return {
      event: null,
      recording: null,
    };
  },
  async mounted() {
    log.log("mounted");
    this.$bind("event", db.collection("LiveEvents").doc(this.$route.params.eventId));
    this.$bind("recording", db.collection(`LiveEvents/${this.$route.params.eventId}/recordings`).doc(this.$route.params.recordingId));
  },
  methods: {
    getRecordingURL,
  }
}
</script>

<style>

</style>