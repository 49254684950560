import { getLog } from "@/services/log";
let log = getLog("recordings");
import { productionStreamingServerIp } from "@/services/appconfig";
import { getHTTPSfromWS } from "@/services/utils";
import { db } from "@/services/db";
import { serverTimestamp } from "./db";

let loadFromGCS = true;

export function getRecordingURL(eventId, recordingId) {
  let bucket = `${eventId}/${recordingId}`;
  let res;
  if (loadFromGCS)
    res = `https://storage.googleapis.com/hh-streams/${bucket}/vod.m3u8`;
  else
    res = `${getHTTPSfromWS(productionStreamingServerIp)}/${bucket}/vod.m3u8`;
  log.log('recording URL', res);
  return res;
}

export async function addQuestionRecording(eventId, data) {
  log.log("addQuestionRecording", eventId, data);
  data.date = serverTimestamp();
  await db.collection(`LiveEvents/${eventId}/questions`).add(data);
}
